import { 
	AbsoluteFill,
	Sequence
} from 'remotion';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types';

import '../fonts.css';

import { FacturaIntroduccion } from './Introduccion';
import { TuPlanIncluye } from './TuPlanIncluye';
import { TotalAPagar } from './TotalAPagar';
import { NoCuenta } from './NoCuenta';

export const myCompSchema2 = z.object({
	logoColor: zColor(),
	tuplanincluye: z.object({
		datos: z.string(),
		hasta: z.string()
	}),
	totalpagar: z.string(),
	noCuenta: z.object({
		cuenta: z.string(),
		sandoAnterior: z.string(),
		cargoFijo: z.string(),
		otrosConceptos: z.string(),
		creditos: z.string()
	}),
});

export const Fija1: React.FC<z.infer<typeof myCompSchema2>> = ({
	logoColor,
	tuplanincluye,
	totalpagar,
	noCuenta
}) => { 

	return (
		<AbsoluteFill className="fija2" style={{
			fontFamily: "Roboto",
		}}>  
			<Sequence durationInFrames={370} name="Introduccion">
				<AbsoluteFill>
					<FacturaIntroduccion logoColor={logoColor}/>
				</AbsoluteFill>
			</Sequence>
 
			<Sequence from={370} durationInFrames={245} name="Tu Plan Incluye">
				<TuPlanIncluye logoColor={logoColor} tuplanincluye={tuplanincluye}/>
			</Sequence>
 
			<Sequence from={620} durationInFrames={800} name="Total A Pagar">
				<TotalAPagar logoColor={logoColor} totalpagar={totalpagar}/>
			</Sequence>
 
			<Sequence from={800} durationInFrames={950} name="No.Cuenta">
				<NoCuenta logoColor={logoColor} noCuenta={noCuenta} totalpagar={totalpagar} />
			</Sequence>
		</AbsoluteFill>
	);
};