import {
	Img,
	staticFile,
	AbsoluteFill, 
	useCurrentFrame, 
} from 'remotion';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types';

export const myCompSchema2 = z.object({
	logoColor: zColor(),
});

export const LineasOlasFechaLimite: React.FC<z.infer<typeof myCompSchema2>> = ({
	logoColor: color1
}) => { 
	const frame = useCurrentFrame(); 
	const marqueeAlt = -1220 + (frame * 4); 
	
	return (
		<AbsoluteFill>
			<div className="waves-lines-wrapp" style={{
				position: "absolute",
				width: 205,
				left: 15,
				top: 140,
				overflow: 'hidden',
			}}>
				<div className="lines-images" style={{
					display: 'flex',
					transform: `translateX(${marqueeAlt}px)`
				}}>
					<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/waves-lines.svg")} style={{
						width: 205
					}}/>
					<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/waves-lines.svg")} style={{
						width: 205,
						margin: 0 -1
					}}/>
					<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/waves-lines.svg")} style={{
						width: 205
					}}/>
					<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/waves-lines.svg")} style={{
						width: 205,
						margin: 0 -1
					}}/>
					<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/waves-lines.svg")} style={{
						width: 205
					}}/>
					<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/waves-lines.svg")} style={{
						width: 205,
						margin: 0 -1
					}}/>
					<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/waves-lines.svg")} style={{
						width: 205
					}}/>
					<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/waves-lines.svg")} style={{
						width: 205,
						margin: 0 -1
					}}/>
					<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/waves-lines.svg")} style={{
						width: 205
					}}/>
					<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/waves-lines.svg")} style={{
						width: 205,
						margin: 0 -1
					}}/>
					<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/waves-lines.svg")} style={{
						width: 205
					}}/>
					<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/waves-lines.svg")} style={{
						width: 205,
						margin: 0 -1
					}}/>
					<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/waves-lines.svg")} style={{
						width: 205
					}}/>
				</div>
			</div>
		</AbsoluteFill>
	);
};