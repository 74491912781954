import { Player } from "@remotion/player"; 
import { Video_CLA_MOV_01 } from "./CLA_MOV_01/TimeLine";

function App() {

  // DATA CLA_MOV_01
  const PropsData = {
		logoColor: '#fff',
		tuplanincluye: {
			datos: "Datos [XX] GB",
			hasta: "* Hasta [XX] GB de datos para compartir por wifi"
		},
		totalpagar: "$986,583",
		noCuenta:{
			cuenta:"117085858",
			sandoAnterior:"$576,416",
			cargoFijo:"$104,650",
			otrosConceptos:"$14,091",
			creditos:"$291,427",
		},
		cargosFijos:"$104,650",
		otrosConceptos:"$6,190",
		creditos:{
			equipo:"Equipo XIA REDMINOT12128 IN",
			cuota:"$79,086",
			total:"$797,520",
			numeroCuota:"1 de 12"
		},
		creaditoMora:{
			equipo:"Equipo XIA REDMINOT12128 IN",
			mora:"$867,842",
			total:"$2,099,513",
			numeroCuota:"6 de 12"
		},
		consumoAdicional:"$24,025",
		otrosConceptosservicios:"$7,900",
		otrosConceptosserviciostxt:"1 Servicio(s) prestado por Google",
		otrosconceptoscagosadicionales:"$5,990",
		otrosconceptoscagosadicionalestxt:"1 compra(s) en Video Juegos - Gameloft",
		fechadepago: "inmediato"
		
	};
  return (
    <div className="App">
      <Player
        controls
        component={  Video_CLA_MOV_01 }
        durationInFrames={2579}
        compositionWidth={1920}
        compositionHeight={1080}
        inputProps={ PropsData }
        fps={30} 
        style={{
          width: '100%',
          height: '100vh',
        }}
      /> 
    </div>
  );
}

export default App;
