import {
	spring, 
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';

import '../../fonts.css';

export const IconoMinutos = () => { 
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig();
	
	const loopDuration = 60;
	const loopFrame = frame % loopDuration;

	const scaleAnimation = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 400 },
		from: 0,
		to: 1,
		delay: 14
	});
	let opacityLinesAnimation = spring({
		fps,
		frame,
		from: 0,
		to: 0,
	});

	if(frame > 14){
		opacityLinesAnimation = spring({
			fps,
			frame,
			from: 0,
			to: 1,
		});
	}

	const levitationEffect = spring({
		fps,
		frame: loopFrame,
		config: { damping: 100, stiffness: 400 },
		from: 0,
		to: 30,
		delay: 40
	});

	return <>
		<div className="icono" style={{
			transform: `translate(40px, -30px) scale(${scaleAnimation})`,
			transformOrigin: "bottom"
		}}>
			<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 78.99 130.34" style={{
				width: 76,
				transform: `translateY(${levitationEffect}px)`,
				transition: 'transform 1s ease'
			}}>
				<g id="Layer_1-2" data-name="Layer 1">
					<g>
						<path className="cls-1" d="m0,75.35C0,60.52,0,45.69,0,30.86c0-6.71,3.65-10.35,10.45-10.37,12.62-.03,25.24-.01,37.85-.01,7.57,0,11.12,3.5,11.13,11.15.03,24.5.02,49,.02,73.5,0,4.88.03,9.76,0,14.64-.04,6.91-3.7,10.57-10.61,10.57-12.8,0-25.6-.01-38.41-.06-6.74-.02-10.38-3.7-10.38-10.44-.01-14.83,0-29.66,0-44.49-.02,0-.04,0-.06,0Zm55.88-2.93h.02c0-10.77-.04-21.55.03-32.32.01-2.25-.52-3.34-3.07-3.32-15.47.1-30.94.09-46.41.03-2.25,0-3.11.8-3.11,3.06,0,21.73-.05,43.46-.15,65.19-.01,2.54.98,3.33,3.46,3.32,15.1-.1,30.2-.07,45.31-.1,3.89,0,3.92-.04,3.92-4.1,0-10.59,0-21.18,0-31.77Zm-26.36,39.19v-.04c-7.64,0-15.29.1-22.93-.05-2.71-.06-3.64.89-3.4,3.5.18,1.92-.03,3.87.11,5.8.28,3.74,2.33,5.7,6.04,5.8,3.13.08,6.26.04,9.39.03,10.31-.01,20.63,0,30.94-.06,4.18-.02,6.04-1.88,6.2-6.07.07-1.93-.1-3.88.06-5.8.2-2.46-.84-3.19-3.2-3.15-7.73.1-15.47.04-23.21.04Zm0-78.42c7.73,0,15.47-.06,23.2.03,2.4.03,3.31-.86,3.16-3.26-.24-3.79-2.14-5.69-6.3-5.7-13.17-.04-26.33-.02-39.5,0-.91,0-1.85.08-2.73.29-2.61.65-4.32,3.51-3.92,6.48.41,3.06,2.85,2.07,4.56,2.1,7.18.1,14.36.04,21.54.05Z" style={{
							strokeWidth: 0
						}}/>
						<g style={{
							opacity: opacityLinesAnimation
						}}>
							<path className="cls-1" d="m61.04,18.42c-1.49-.37-2.08-1.28-1.38-2.46,2.44-4.09,5.05-8.08,8.07-11.77.64-.78,1.6-.91,2.46-.18.85.73.42,1.52-.05,2.21-2.52,3.72-5.04,7.43-7.62,11.11-.34.48-1,.74-1.47,1.08Z" style={{
								strokeWidth: 0
							}}/>
							<path className="cls-1" d="m76.7,16.88c1.1.01,1.82.17,2.15.95.42.99-.21,1.73-.95,2-4.12,1.49-8.28,2.88-12.44,4.25-.8.26-1.4-.25-1.76-.97-.42-.85.11-1.39.66-1.89,1.07-.98,2.51-1.06,3.79-1.52,2.92-1.04,5.89-1.95,8.55-2.82Z" style={{
								strokeWidth: 0
							}}/>
							<path className="cls-1" d="m56.01,10.63c0,1.37.05,2.74-.02,4.11-.04.66-.42,1.22-1.19,1.3-.85.09-1.54-.31-1.64-1.11-.53-4.44-1.34-8.86-1.2-13.36.03-.95.62-1.53,1.58-1.56,1.07-.03,1.39.77,1.5,1.63.39,2.99.73,5.99,1.1,8.98-.04,0-.09.01-.13.02Z" style={{
								strokeWidth: 0
							}}/>
						</g>
						<path className="cls-1" d="m29.57,125.08c-3-.05-5.39-2.51-5.33-5.5.06-3.19,2.51-5.57,5.66-5.49,2.95.07,5.44,2.61,5.42,5.52-.02,3.02-2.66,5.53-5.76,5.47Zm2-5.17c.05-1.16-.41-2.02-1.58-2.19-1.27-.18-2.13.47-2.26,1.76-.12,1.19.67,1.9,1.74,2.07,1.17.19,1.85-.53,2.1-1.65Z" style={{
							strokeWidth: 0
						}}/>
						<path className="cls-1" d="m29.77,26.93c2.66,0,5.32-.03,7.98.02,1.02.02,2.13.18,2.12,1.59-.02,1.38-1.07,1.59-2.13,1.59-5.32.02-10.64.03-15.96,0-1.14,0-2.35-.29-2.28-1.78.07-1.38,1.31-1.38,2.29-1.4,2.66-.06,5.32-.02,7.98-.02,0,0,0,0,0,.01Z" style={{
							strokeWidth: 0
						}}/>
					</g>
				</g>
			</svg>		
		</div>
	</>;
};