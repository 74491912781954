import {
	Img,
	staticFile,
	AbsoluteFill,
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';

export const CirculoPuntosRojo = () => { 
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig(); 

	const scaleNormal = spring({
		fps,
		frame,
	});
	
	return (
		<AbsoluteFill>
			<Img className="fondo-chica" src={staticFile("CLA_MOV_01/circulo-puntos-rojo.svg")} style={{
				position: "absolute",
				width: 144,
				right: "20%",
				bottom: "10%",
				transform: `scale(${scaleNormal}) rotate(${frame}deg)`,
			}}/>
		</AbsoluteFill>
	);
};