import {
	Img,
	staticFile,
	AbsoluteFill,
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types';

export const myCompSchema2 = z.object({
	logoColor: zColor(),
});

export const CirclesWhite: React.FC<z.infer<typeof myCompSchema2>> = ({
	logoColor: color1
}) => { 
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig(); 

	const scaleNormal = spring({
		fps,
		frame,
	});
	
	return (
		<AbsoluteFill>
			<Img className="fondo-chica" src={staticFile("CLA_MOV_01/circle-dots.svg")} style={{
				position: "absolute",
				width: 144,
				left: -72,
				top: 80,
				transform: `scale(${scaleNormal}) rotate(${frame}deg)`,
			}}/>
		</AbsoluteFill>
	);
};