import { 
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';

import '../../fonts.css';

import React, { ReactNode } from 'react';
interface SocialLinkProps {
  children: ReactNode;
  enlace: string;
  retraso?: number;
}

export const SocialLink: React.FC<SocialLinkProps> = ({
  children,
  enlace,
  retraso,
}) => { 
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig();
	 
	const scaleNormal = spring({
		fps,
		frame,
		from: 0,
		to: 1,
		delay: retraso
	});
	
	return <>
		<a href={enlace} target="_blank" style={{
			display: "block",
			boxShadow: "0 0 0 0",
			transform: `scale(${scaleNormal})`,
		}}>
			{children}
		</a>
	</>;
};

SocialLink.defaultProps = {
	enlace: '',
	retraso: 0
};