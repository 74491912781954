import {
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types';

export const myCompSchema2 = z.object({
	logoColor: zColor(),
});

export const LogoClaro: React.FC<z.infer<typeof myCompSchema2>> = ({
	logoColor: color1
}) => { 
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig();
	
	const loopDuration = 60;
	const loopFrame = frame % loopDuration;

	// ? LINE #1
	const lineOneStart = { x: 0, y: 39 };
	const lineOneEnd = { x: 0, y: 0 };

	const lineOneX = spring({
		fps,
		frame: loopFrame,
		config: { damping: 100, stiffness: 100 },
		from: lineOneStart.x,
		to: lineOneEnd.x,
		delay: 18
	});
	const lineOneY = spring({
		fps,
		frame: loopFrame,
		config: { damping: 100, stiffness: 100 },
		from: lineOneStart.y,
		to: lineOneEnd.y,
		delay: 18
	});
	const lineOneScale = spring({
		frame: loopFrame,
		fps,
		config: {
		  damping: 100,
		},
		from: 0,
		to: 1,
		delay: 18
	});

	// ? LINE #2
	const lineTwoStart = { x: -50, y: 50 };
	const lineTwoEnd = { x: 0, y: 0 };

	const lineTwoX = spring({
		fps,
		frame: loopFrame,
		config: { damping: 100, stiffness: 100 },
		from: lineTwoStart.x,
		to: lineTwoEnd.x,
		delay: 24
	});
	const lineTwoY = spring({
		fps,
		frame: loopFrame,
		config: { damping: 100, stiffness: 100 },
		from: lineTwoStart.y,
		to: lineTwoEnd.y,
		delay: 24
	});
	const lineTwoScale = spring({
		frame: loopFrame,
		fps,
		config: {
		  damping: 100,
		},
		from: 0,
		to: 1,
		delay: 24
	});

	// ? LINE #3
	const lineThreeStart = { x: -50, y: 0 };
	const lineThreeEnd = { x: 0, y: 0 };

	const lineThreeX = spring({
		fps,
		frame: loopFrame,
		config: { damping: 100, stiffness: 100 },
		from: lineThreeStart.x,
		to: lineThreeEnd.x,
		delay: 30
	});
	const lineThreeY = spring({
		fps,
		frame: loopFrame,
		config: { damping: 100, stiffness: 100 },
		from: lineThreeStart.y,
		to: lineThreeEnd.y,
		delay: 30
	});
	const lineThreeScale = spring({
		frame: loopFrame,
		fps,
		config: {
		  damping: 100,
		},
		from: 0,
		to: 1,
		delay: 30
	});

	const scaleCircle = spring({
		frame: loopFrame,
		fps,
		config: {
		  damping: 100,
		},
		from: 0,
		to: 1,
	});
	

	const lineOneTransform = `translate(${lineOneX}px, ${lineOneY}px) scale(${lineOneScale})`;
	const lineTwoTransform = `translate(${lineTwoX}px, ${lineTwoY}px) scale(${lineTwoScale}) rotate(-45deg)`;
	const lineThreeTransform = `translate(${lineThreeX}px, ${lineThreeY}px) scale(${lineThreeScale})`;
	return (
		<div className="claro-logo white">
			<div className="c-wrap">
				<div className="c-circle" style={{
						borderColor: color1,
						transformOrigin: 'center center',
						transform: `scale(${scaleCircle})`,
					}}/>
				<span className="c-line line-1" style={{ backgroundColor: color1, transform: lineOneTransform }} />
				<span className="c-line line-2" style={{ backgroundColor: color1, transform: lineTwoTransform }}/>
				<span className="c-line line-3" style={{ backgroundColor: color1, transform: lineThreeTransform }}/>
			</div>
		</div>
	);
};