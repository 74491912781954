import {
	spring, 
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';

import '../../fonts.css';

export const IconoSms = () => { 
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig();
	
	const loopDuration = 60;
	const loopFrame = frame % loopDuration;

	const scaleAnimation = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 400 },
		from: 0,
		to: 1,
		delay: 14
	});

	const levitationEffectOne = spring({
		fps,
		frame: loopFrame,
		config: { damping: 100, stiffness: 400 },
		from: -10,
		to: 0
	});
	const levitationEffectTwo = spring({
		fps,
		frame: loopFrame,
		config: { damping: 100, stiffness: 400 },
		from: -10,
		to: 0,
	});

	const levitationEffectThree = spring({
		fps,
		frame: loopFrame,
		config: { damping: 100, stiffness: 400 },
		from: -10,
		to: 0,
	});

	return <>
		<div className="icono" style={{
			transform: `translate(30px, -15px) scale(${scaleAnimation})`,
			transformOrigin: "bottom"
		}}>
			<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.29 87.96" style={{
				width: 100
			}}>
				<g id="Layer_1-2" data-name="Layer 1">
					<g>
						<path className="cls-1" d="m50.89,0C62.88,0,74.86,0,86.84.01c8.61,0,12.36,3.43,12.4,11.93.09,24.29.03,48.58-.01,72.87,0,1.11.48,2.67-1.05,3.09-1.24.34-1.77-1.08-2.45-1.83-4-4.45-8.03-8.89-11.84-13.5-2.04-2.47-4.27-3.5-7.55-3.48-21.13.15-42.26.03-63.4.1-5.25.02-9.36-1.69-11.75-6.59C.06,60.28.12,57.74.11,55.24.09,41.41.34,27.57,0,13.74-.22,4.59,4.79.26,13.32.11c12.52-.22,25.05-.06,37.58-.06,0-.02,0-.03,0-.05Zm-.08,5.23c-6.97,0-13.94-.01-20.9,0-5.66.01-11.33-.13-16.98.15-5.91.3-7.59,2.16-7.61,7.86-.04,14.15-.06,28.31.02,42.46.03,6.48,1.96,8.26,8.52,8.26,19.16,0,38.32-.04,57.49.02,12.97.04,10.41-1.41,19.47,8.57.73.81,1.3,2.45,2.61,1.94,1.06-.42.6-1.96.6-3,.02-19.6.04-39.19,0-58.79-.01-6.08-1.44-7.45-7.6-7.46-11.87-.03-23.73,0-35.6-.01Z" style={{
							strokeWidth: 0
						}}/>
						<path className="cls-1" d="m86.07,34.36c.03,5.12-3.65,8.96-8.65,9.01-5.11.05-9.29-3.95-9.28-8.89,0-4.7,4.25-8.95,8.99-9.01,4.79-.06,8.91,4.04,8.94,8.89Zm-4.4.11c-.02-2.38-1.92-4.25-4.37-4.28-2.68-.04-4.39,1.75-4.33,4.52.05,2.61,1.68,4.15,4.37,4.11,2.39-.03,4.35-2.01,4.34-4.35Z" style={{
							strokeWidth: 0,
							transform: `translateY(${levitationEffectOne}px)`,
							transition: 'transform .2s ease'
						}}/>
						<path className="cls-1" d="m58.83,34.54c.05,5.01-3.76,8.83-8.81,8.85-4.91.02-8.99-3.91-9.1-8.76-.11-4.79,4.29-9.2,9.16-9.16,4.87.03,8.7,4,8.75,9.08Zm-8.65-4.31c-3.05.08-4.64,1.8-4.52,4.59.11,2.49,1.99,4.24,4.42,4.13,2.3-.11,4.85-2.6,4.4-4.66-.51-2.29-1.66-4.28-4.3-4.06Z" style={{
							strokeWidth: 0,
							transform: `translateY(${levitationEffectTwo}px)`,
							transition: 'transform .4s ease'
						}}/>
						<path className="cls-1" d="m22.07,43.39c-4.95-.03-8.85-3.96-8.82-8.9.03-4.84,4.15-9.04,8.86-9,4.92.03,9.25,4.39,9.14,9.2-.11,4.8-4.25,8.73-9.17,8.71Zm.07-13.15c-2.5,0-4.45,2.06-4.36,4.62.07,2.12,2.45,4.39,4.6,4.12,2.55-.32,4.12-1.74,4.07-4.54-.05-2.61-1.64-4.2-4.31-4.2Z" style={{
							strokeWidth: 0,
							transform: `translateY(${levitationEffectThree}px)`,
							transition: 'transform .6s ease'
						}}/>
					</g>
				</g>
			</svg>		
		</div>
	</>;
};