import { 
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';

import '../../fonts.css';

import React, { ReactNode } from 'react';

interface PlanIncluyeCajaProps {
  children: ReactNode;
  shadow?: boolean;
  titulo?: string;
  parrafo?: string;
  parrafoWidth?: number;
}

export const PlanIncluyeCaja: React.FC<PlanIncluyeCajaProps> = ({
  children,
  shadow,
  titulo,
  parrafo,
  parrafoWidth,
}) => { 
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig(); 


	const opacityAnimation = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 400 },
		from: 0,
		to: 1,
	});
	const heightAnimation = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 400 },
		from: 0,
		to: 100,
		durationInFrames: 40,
	});
	
	const tituloScaleAnimation = spring({
		frame,
		fps,
		config: {
			damping: 200,
		},
		from: 0,
		to: 1,
		delay: 20
	});

	const parrafoScaleAnimation = spring({
		frame,
		fps,
		config: {
			damping: 200,
		},
		from: 0,
		to: 1,
		delay: 30
	});

	return <>
		<div className="tpl-item" style={{
			fontFamily: "Roboto",
			position: "relative",
			color: "#DA3832",
			width: 640,
			padding: "5px 0",
			height: "100%",
			display: "flex",
			zIndex: 1,
			alignItems: "center",
		}}>
			<div className="tpl-bg" style={{
				backgroundColor: '#fff',
				position: "absolute",
				left: 0,
				top: 0,
				zIndex: -1,
				width: "100%",
				opacity: `${opacityAnimation}`,
				height: `${heightAnimation}%`,
				borderRadius: 20,
				boxShadow: (shadow)? "0 0 18px 8px #00000024" : "0 0 0 0"
			}}/>
			<div className="tpl-box" style={{
				display: 'flex',
				gap: 50,
				alignItems: 'center',
			}}>
				{children}
				<div className="titulo">
					<h4 style={{
						fontStyle: 'bold',
						fontSize: 53,
						transform: `scale(${tituloScaleAnimation})`
					}}>
						{titulo}
					</h4>
				</div>
				<div className="parrafo" style={{
						fontStyle: '500',
						fontSize: 29,
						width: (parrafoWidth && parrafoWidth > 0) ? parrafoWidth : 'auto',
						transform: `scale(${parrafoScaleAnimation})`
					}}>
						{parrafo}
				</div>
			</div>
		</div>
	</>;
};

PlanIncluyeCaja.defaultProps = {
	shadow: false,
	titulo: '',
	parrafo: '',
	parrafoWidth: 0
};