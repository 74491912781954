import {
	Img,
	staticFile,
	spring,
	AbsoluteFill,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';

import '../../fonts.css';

export const FacturaClaro = () => { 
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig(); 

	const leftAnimation = (frame > 1)? 100 : 700;
	const rightAnimation = (frame > 1)? 60 : 700;

	return <AbsoluteFill style={{
		fontFamily: 'AMX',
		textAlign: 'center',
		justifyContent: 'flex-end',
	}}>
		<h4 style={{
			position: "absolute",
			top: "50%",
			left: `${leftAnimation}px`,
			margin: 0,
			color: "#fff",
			fontSize: 124,
			lineHeight: 1,
			fontWeight: 'bold',
			transform: `translateY(-50%)`,
			transition: "left 1s ease"
		}}>Factura</h4>
		<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/claro-logo-completo.svg")} style={{
			position: "absolute",
			top: "50%",
			right: rightAnimation,
			width: 355,
			transform: `translateY(-50%)`,
			transition: "right 1s ease"
		}}/>
	</AbsoluteFill>;
};