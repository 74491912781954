import {
	spring,
	AbsoluteFill,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';

import '../../fonts.css';

export const HolaTexto = () => { 
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig();
	const letters = "¡Hola!";

	const tituloScaleAnimation = spring({
		frame: frame * 2,
		fps,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 1
	});

	return <AbsoluteFill style={{
		fontFamily: 'AMX',
		textAlign: 'center',
		justifyContent: 'flex-end',
	}}>
		<h4 style={{
			position: "relative",
			margin: 0,
			color: "#fff",
			fontSize: 174,
			lineHeight: 1,
			fontWeight: 'bold',
			transform: `translateY(-140px) scale(${tituloScaleAnimation})`
		}}>
			{letters}
		</h4>
	</AbsoluteFill>;
};