import {
	Img,
	staticFile, 
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';

export const ManoClick = () => { 
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig(); 
	
	const topAnimation = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 100,
		to: 44,
	});
	const newTopAnimation = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 44,
		to: 100,
		delay: 60
	});

	const rotateAnimation = spring({
		fps,
		frame: frame * 1.5,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: -4,
		delay: 20
	});
	const translateAnimation = spring({
		fps,
		frame: frame * 1.5,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 20,
		delay: 20
	});
	const newRotateAnimation = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: -4,
		to: 0,
		delay: 26
	});
	const newTranslateAnimation = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 20,
		to: 0,
		delay: 26
	});
	
	return <>
		<Img className="mano-click" src={staticFile("CLA_MOV_01/mano-click.png")} style={{
			position: "absolute",
			width: 487,
			right: "22%",
			top: `${(frame > 60)? newTopAnimation : topAnimation }%`,
			transform: `translateY(${(frame > 26)? newTranslateAnimation : translateAnimation}px) rotate(${(frame > 26)? newRotateAnimation : rotateAnimation}deg)`
		}}/>
	</>
};