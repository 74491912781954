import {
	Img,
	staticFile,
	AbsoluteFill,
	Sequence,
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types';

import '../fonts.css';

import { LogoClaro } from './Components/LogoClaro';
import { PlanIncluyeCaja } from './Components/PlanIncluyeCaja';
import { IconoMinutos } from './Components/IconoMinutos';
import { IconoDatos } from './Components/IconoDatos';
import { IconoSms } from './Components/IconoSms';
import { IconoLdi } from './Components/IconoLdi';
import { SocialLink } from './Components/SocialLink';

export const myCompSchema2 = z.object({
	logoColor: zColor(),
	tuplanincluye: z.object({
		datos: z.string(),
		hasta: z.string()
	}),
});

export const TuPlanIncluye: React.FC<z.infer<typeof myCompSchema2>> = ({
	logoColor,
	tuplanincluye
}) => {
	// Const videoConfig = useVideoConfig();
	const frame = useCurrentFrame();

	const { durationInFrames, fps } = useVideoConfig(); 
	
	const letters = "Tu plan Incluye";

	const translateBg = -960 - frame;

	const scaleBg = spring({
		fps,
		frame: frame * 5,
		config: { damping: 400, stiffness: 400 },
		from: 0.8,
		to: 1
	});
	const blurBg = spring({
		fps,
		frame: frame * 5,
		config: { damping: 400, stiffness: 400 },
		from: 10,
		to: 0,
	});

	const tuPlanTexto = tuplanincluye.hasta;
	const appsLimiteTexto = "Apps sin límite de consumo";

	let whiteBoxWidthAnimation = spring({
		fps,
		frame,
		config: { damping: 400, stiffness: 400 },
		from: 0,
		to: 0,
	});

	if(frame > 60){
		whiteBoxWidthAnimation = spring({
			fps,
			frame,
			config: { damping: 400, stiffness: 400 },
			from: 0,
			to: 100,
		});
	}

	const scaleMiClaro = spring({
		fps,
		frame,
		from: 0,
		to: 1,
		delay: 100
	});
	const scaleMiClaroTexto = spring({
		fps,
		frame,
		from: 0,
		to: 1,
		delay: 100
	});
	const miclaroTexto = "Consulta todas tus Apps en";

	const scaleSection = spring({
		fps,
		frame,
		config: { damping: 400, stiffness: 400 },
		from: 1,
		to: 2,
		delay: (durationInFrames - 8)
	});
	const blurSection = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 10,
		delay: (durationInFrames - 8)
	});
	
	const tuPlanTextoScale = spring({
		fps,
		frame,
		config: { damping: 400, stiffness: 400 },
		from: 0,
		to: 1,
		delay: 60
	});

	const appsLimiteTextoScale = spring({
		fps,
		frame,
		config: { damping: 400, stiffness: 400 },
		from: 0,
		to: 1,
		delay: 65
	});

	const scaleTitulo = spring({
		fps,
		frame,
		config: { damping: 400, stiffness: 400 },
		from: 0,
		to: 1,
		delay: 5
	});

	return (
		<AbsoluteFill className="tuplanincluye" style={{
			fontFamily: "Roboto",
			filter: (blurSection > 0)? `blur(${blurSection}px)` : '',
			transform: `scale(${scaleSection})`
		}}>
			<AbsoluteFill>
				<Img className="fondo-blanco" src={staticFile("CLA_MOV_01/fondo-horizontal-blanco-min.png")} style={{
					position: "absolute",
					filter: (blurBg === 0)? '' : `blur(${blurBg}px)`,
					transform: `translate(${translateBg}px, 0px) scale(${scaleBg})`,
					width: "200%"
				}}/>
				<h2 style={{
					position: 'absolute',
					fontFamily: 'AMX',
					width: "100%",
					left: 0,
					top: 100,
					margin: 0,
					color: "#000",
					fontSize: 76,
					lineHeight: 1,
					fontWeight: 'bold',
					textAlign: "center",
					transform: `scale(${scaleTitulo})`
				}}>
					{letters}
				</h2>
			</AbsoluteFill>
			<Sequence from={1} name="Logo Claro">
				<LogoClaro logoColor="#DA3832"/>
			</Sequence>
			<Sequence from={20} durationInFrames={225} name="Minutos Ilimitados" style={{
				display: "block"
			}}>
				<div className="tpl-lista" style={{
					display: "grid",
					width: "100%",
					maxWidth: 1390,
					margin: "0 auto",
					paddingTop: 270,
					gap: "50px 100px",
					alignItems: 'start',
					justifyContent: "center",
					gridTemplateColumns: "repeat(2, 1fr)"
				}}>
					<PlanIncluyeCaja shadow titulo="Minutos Ilimitados">
						<IconoMinutos/>
					</PlanIncluyeCaja>
					<PlanIncluyeCaja shadow titulo={tuplanincluye.datos+""}>
						<IconoDatos/>
					</PlanIncluyeCaja>
					<PlanIncluyeCaja shadow titulo="SMS" parrafo="Ilimitados a todo destino" parrafoWidth={220}>
						<IconoSms/>
					</PlanIncluyeCaja>
					<PlanIncluyeCaja shadow titulo="LDI" parrafo="Minutos Ilimitados a México, Puerto Rico, USA y Canadá" parrafoWidth={280}>
						<IconoLdi/>
					</PlanIncluyeCaja>
				</div>
				<div className="tuplan-parrafo" style={{
					fontWeight: '500',
					fontSize: 30,
					textAlign: 'center',
					paddingTop: 40,
					paddingBottom: 70,
					minHeight: 147,
					transform: `scale(${tuPlanTextoScale})`
				}}>
					{tuPlanTexto}
				</div>
				<div className="apps-sin-limite">
					<div className="app-box" style={{
						position: 'relative',
						maxWidth: 1030,
						margin: '0 auto'
					}}>
						<div className="linea">
							<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1034.25 166.85">
								<g id="Layer_1-2" data-name="Layer 1">
									<rect className="cls-1" x="2.12" y="2.12" width="1030" height="162.6" rx="41.9" ry="41.9" style={{
										fill: "none",
										stroke: "#da3832",
										strokeMiterlimit: 10,
										strokeWidth: "4.25px",
										strokeDashoffset: 0,
  										strokeDasharray: "2314.83984375px"
									}}/>
								</g>
							</svg>
						</div>
						<h4 style={{
							position: "absolute",
							fontWeight: '500',
							fontSize: 53,
							width: "100%",
							top: -27,
							left: "50%",
							maxWidth: 744,
							textAlign: 'center',
							transform: "translateX(-50%)"
						}}>
							<div className="bg-box" style={{
								backgroundColor: "#fff",
								position: 'absolute',
								left: 0,
								top: 0,
								width: `${whiteBoxWidthAnimation}%`,
								height: "100%",
								borderRadius: 18,
								transition: "width 1s ease",
							}}/>
							<span style={{
								position: "relative",
								display: 'block',
								transform: `scale(${appsLimiteTextoScale})`,
							}}>{appsLimiteTexto}</span>
						</h4>
						{(frame > 60)? (
							<div className="redes" style={{
								position: 'absolute',
								fontWeight: '500',
								fontSize: 53,
								width: "100%",
								display: "flex",
								gap: 15,
								top: "60%",
								left: "50%",
								borderRadius: 18,
								justifyContent: "center",
								transform: "translate(-50%, -50%)"
							}}>
								<SocialLink enlace="#fb" retraso={40}>
									<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/fb-icon.svg")} style={{
										width: 80
									}}/>
								</SocialLink>
								<SocialLink enlace="#in" retraso={50}>
									<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/in-icon.svg")} style={{
										width: 80
									}}/>
								</SocialLink>
								<SocialLink enlace="#x" retraso={60}>
									<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/x-icon.svg")} style={{
										width: 80
									}}/>
								</SocialLink>
								<SocialLink enlace="#wb" retraso={70}>
									<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/wp-icon.svg")} style={{
										width: 80
									}}/>
								</SocialLink>
							</div>
						) : ''}
					</div>
				</div>
				<div className="miclaro" style={{
					display: "flex",
					paddingTop: 20,
					alignItems: "center",
					justifyContent: "center"
				}}>
					<div className="txto" style={{
						fontWeight: '500',
						fontSize: 30,
						marginBottom: 16,
						transform: `scale(${scaleMiClaroTexto})`
					}}>
						{miclaroTexto}
					</div>
					<a href="#miclaro" target="_blank" style={{
						display: "block",
						transform: `scale(${scaleMiClaro})`
					}}>
						<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/miclaro-logo.png")} style={{
							width: 129
						}}/>
					</a>
				</div>
			</Sequence>
		</AbsoluteFill>
	);
};