import {
	Img,
	staticFile,
} from 'remotion';

import '../../fonts.css';

import { ElementoEstadoCuenta } from './ElementoEstadoCuenta'; 


interface LitaEstadoCuentaProps {
	sandoAnterior: string;
	cargoFijo: string;
	otrosConceptos: string;
	creditos: string;
}

export const LitaEstadoCuenta: React.FC<LitaEstadoCuentaProps> = ({
	sandoAnterior,
	cargoFijo,
	otrosConceptos,
	creditos
}) => {
	return <>
		<div className="tpl-lista" style={{
			position: "absolute",
			display: "grid",
			right: 140,
			top: "50%",
			width: "100%",
			maxWidth: 902,
			margin: "0 auto",
			gap: "25px",
			alignItems: 'start',
			justifyContent: "center",
			gridTemplateColumns: "repeat(1, 1fr)",
			transform: "translateY(-50%)"
		}}>
			<ElementoEstadoCuenta shadow titulo="Saldo Anterior" precio={sandoAnterior} retraso={10}>
				<Img className="imagen-icono" src={staticFile("CLA_MOV_01/icono-saldo-anterior.svg")} style={{
					width: 51,
				}}/>
			</ElementoEstadoCuenta>
			<ElementoEstadoCuenta shadow titulo="Cargos Fijos" precio={cargoFijo} retraso={20}>
				<Img className="imagen-icono" src={staticFile("CLA_MOV_01/icono-cargos-fijos.svg")} style={{
					width: 36,
				}}/>
			</ElementoEstadoCuenta>
			<ElementoEstadoCuenta shadow titulo="Consumo Adicional" inactive={true} retraso={30}>
				<Img className="imagen-icono" src={staticFile("CLA_MOV_01/icono-consumo-adicional.svg")} style={{
					width: 46,
				}}/>
			</ElementoEstadoCuenta>
			<ElementoEstadoCuenta shadow titulo="Otros Conceptos" precio={otrosConceptos} retraso={40}>
				<Img className="imagen-icono" src={staticFile("CLA_MOV_01/icono-otros-conceptos.svg")} style={{
					width: 37,
				}}/>
			</ElementoEstadoCuenta>
			<ElementoEstadoCuenta shadow titulo="Créditos" precio={creditos} retraso={50}>
				<Img className="imagen-icono" src={staticFile("CLA_MOV_01/icono-creditos.svg")} style={{
					width: 22,
				}}/>
			</ElementoEstadoCuenta>
			<ElementoEstadoCuenta shadow titulo="Descuentos" inactive={true} retraso={60}>
				<Img className="imagen-icono" src={staticFile("CLA_MOV_01/icono-descuentos.svg")} style={{
					width: 35,
				}}/>
			</ElementoEstadoCuenta>
		</div>
	</>
};