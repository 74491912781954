import {
	Img,
	staticFile,
	AbsoluteFill,
	Sequence,
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types';

import '../fonts.css';
import { CirculoLineasNegro } from './Components/CirculoLineasNegro';
import { CirculoPuntosRojo } from './Components/CirculoPuntosRojo';
import { LogoClaro } from './Components/LogoClaro';
import { ManoClick } from './Components/ManoClick';

export const myCompSchema2 = z.object({
	logoColor: zColor(),
});

export const QuieresPagarTuFactura: React.FC<z.infer<typeof myCompSchema2>> = ({
	logoColor
}) => {
	// Const videoConfig = useVideoConfig();
	const frame = useCurrentFrame();

	const { durationInFrames, fps } = useVideoConfig();
	
	const loopDuration = 60;
	const loopFrame = frame % loopDuration;
	 
	
	const letters = "¿Quieres pagar tu factura Claro?";
	const textoPagar = "Pagar";

	const opacityUno = spring({
		fps,
		frame: loopFrame,
		from: 0,
		to: .10
	});
	const scaleUno = spring({
		fps,
		frame: loopFrame,
		config: { damping: 15, stiffness: 100 },
		from: 0,
		to: 1.4
	});

	const opacityDos = spring({
		fps,
		frame: loopFrame,
		from: 0,
		to: .35
	});
	const scaleDos = spring({
		fps,
		frame: loopFrame,
		config: { damping: 15, stiffness: 100 },
		from: 0,
		to: 1.2,
		delay: 5
	});
	const scaleTres = spring({
		fps,
		frame,
		config: { damping: 15, stiffness: 100 },
		from: 0,
		to: 1,
		delay: 10
	});

	const scaleSection = spring({
		fps,
		frame,
		config: { damping: 400, stiffness: 400 },
		from: 1,
		to: 2,
		delay: (durationInFrames - 8)
	});
	const blurSection = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 10,
		delay: (durationInFrames - 8)
	});
	
	const letterTranslate = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: -50,
		to: 0,
		delay: 5
	});

	const letterScale = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 1,
		delay: 5
	});

	return (
		<AbsoluteFill className="total-a-pagar" style={{
			fontFamily: "Roboto",
			filter: (blurSection > 0)? `blur(${blurSection}px)` : '',
			transform: `scale(${scaleSection})`
		}}>
			<AbsoluteFill>
				<Img className="fondo-chica" src={staticFile("CLA_MOV_01/total-a-pagar-fondo.jpg")} style={{
					position: "absolute"
				}}/>
			</AbsoluteFill>
			<Sequence from={1} name="Logo Claro">
				<LogoClaro logoColor="#DA3832"/>
			</Sequence>
			<AbsoluteFill>
				<CirculoLineasNegro/>
				<CirculoPuntosRojo/>
				<div className="total-caja" style={{
					position: "absolute",
					top: "40%",
					left: 0,
					width: "100%",
					transform: "translateY(-50%)"
				}}>
					<h2 style={{
						fontFamily: 'AMX',
						width: "100%",
						left: 0,
						margin: 0,
						color: "#000",
						fontSize: 76,
						lineHeight: 1,
						fontWeight: 'bold',
						textAlign: "center",
						transform: `scale(${letterScale})`
					}}>
						{letters}
					</h2>
					<div className="box-lineas">
						<div className="box-wrap">
							<div className="bx-line" style={{
								maxWidth: 570,
								opacity: opacityUno,
								transform: `translateY(-50%) scale(${scaleUno})`
							}}/>
							<div className="bx-line" style={{
								maxWidth: 624,
								opacity: opacityDos,
								transform: `translateY(-50%) scale(${scaleDos})`
							}}/>
							<div className="bx-line" style={{
								transform: `translateY(-50%) scale(${scaleTres})`
							}}/>
						</div>
					</div>
				</div>
			</AbsoluteFill>
			<Sequence from={60} name="Mano Click">
				<ManoClick/>
			</Sequence>
			<AbsoluteFill>
				<a href="#hola" className="enlace-pagar" target="_blank" style={{
					position: "absolute",
					left: 0,
					top: "41%",
					color: "#000",
					width: "100%",
					display: "flex",
					fontSize: 120,
					outline: "none",
					fontWeight: "500",
					textDecoration: "none",
					boxShadow: "0 0 0 0",
					justifyContent: "center"
				}}>
					{(textoPagar)? textoPagar.split(' ').map((letter, index) => {
						const delayFrames = (index + 5) * 20;
						const newFrames = frame * 5;
						const scaleAnimation = spring({
							frame: newFrames,
							fps,
							config: {
								damping: 100,
							},
							from: 0,
							to: 1,
							delay: delayFrames
						});
						const translateAnimation = spring({
							frame: newFrames,
							fps,
							config: {
								stiffness: 10,
								damping: 200,
							},
							from: 50,
							to: 0,
							delay: delayFrames
						})
						return (
						<span
							key={index}
							style={{
								position: 'relative',
								display: "inline-block",
								transform: `scale(${scaleAnimation}) translateY(${translateAnimation}px)`,
							}}
						>
							{letter === ' ' ? '\u00A0' : letter+'\u00A0'}
						</span>
						);
					}) : ''}
				</a>
			</AbsoluteFill>
		</AbsoluteFill>
	);
};