import {
	Img,
	staticFile,
	AbsoluteFill,
	Sequence,
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types';
import { OverlayIntroduccion } from './OverlayIntroduccion'; 

import { LogoClaro } from './Components/LogoClaro';
import { CirclesWhite } from './Components/CirclesWhite'; 
import { HolaTexto } from './Components/HolaTexto';
import { FacturaClaro } from './Components/FacturaClaro';

export const myCompSchema2 = z.object({
	logoColor: zColor(),
});

export const FacturaIntroduccion: React.FC<z.infer<typeof myCompSchema2>> = ({
	logoColor
}) => {
	// Const videoConfig = useVideoConfig();
	const frame = useCurrentFrame();

	const { durationInFrames, fps } = useVideoConfig(); 
	
	const frameCalc = (frame > 2)? (frame * 1000) : 0;
	const dashoffAnimation = 2388 + frameCalc;
	
	const widthAnimation = frame > 115
    ? '818px' : '625px';
	const radiusAnimation = frame > 115
    ? "90px" : "70px";

	const widthGirlAnimation = frame > 115
    ? '100%' : '65%';
	const heightGirlAnimation = frame > 115
    ? '100%' : 620;

	const scaleSection = spring({
		fps,
		frame,
		config: { damping: 400, stiffness: 400 },
		from: 1,
		to: 2,
		delay: (durationInFrames - 8)
	});
	const blurSection = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 10,
		delay: (durationInFrames - 8)
	});

	return (
		<AbsoluteFill className="introduccion" style={{
			filter: (blurSection > 0)? `blur(${blurSection}px)` : '',
			transform: `scale(${scaleSection})`
		}}>
			<AbsoluteFill>
				<Img className="fondo-chica" src={staticFile("CLA_MOV_01/factura/fondo-chica.jpg")} style={{
					position: "absolute"
				}}/>
			</AbsoluteFill>
			<Sequence from={90} durationInFrames={370} name="Overlay">
				<OverlayIntroduccion logoColor="#ddd"/>
			</Sequence>
			<Sequence from={70} durationInFrames={370} name="Chicha Transparent">
				<AbsoluteFill style={{
					width: widthGirlAnimation,
					height: heightGirlAnimation,
					overflow: "hidden",
					zIndex: (frame > 52)? 110 : 1,
					transition: "width 1s ease, height 1s ease"
				}}>
					<Img className="fondo-chica" src={staticFile("CLA_MOV_01/factura/fondo-solo-chica.png")} style={{
						position: "absolute"
					}}/>
				</AbsoluteFill>
			</Sequence>
			<Sequence from={144} durationInFrames={370} name="Factura Claro">
				<FacturaClaro/>
			</Sequence>
			<Sequence from={70} durationInFrames={370} name="Cuadro">
				<div className="cuadro-imagen" style={{
					position: 'absolute',
					top: 50,
					left: "645px",
					width: widthAnimation,
					height: 'auto',
					overflow: 'hidden',
					borderRadius: radiusAnimation,
					transition: "width 1s ease, border-radius 1s ease"
				}}>
					<Img className="fondo-chica" src={staticFile("CLA_MOV_01/factura/fondo-chica.jpg")} style={{
						position: 'absolute',
						left: "432px",
						top: "-50px",
						zIndex: 1,
						transform: "translateX(-50%)"
					}}/>
					<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 628.76 628.76" width="628.760009765625" style={{
						position: "relative",
						width: "100%",
						zIndex: 2,
						display: 'block',
						transform: "rotateX(182deg)"
					}}>
						<g id="Layer_1-2" data-name="Layer 1">
							<rect className="cls-1 svg-elem-1" x="1.52" y="1.52" width="625.73" height="625.72" rx="67.69" ry="67.69" style={{
								fill: "none",
								stroke: "#fff",
								strokeMiterlimit: 10,
								strokeWidth: "3.04px",
								strokeDashoffset: `${(dashoffAnimation >= 4776.00390625)? 4776.00390625 : dashoffAnimation}px`
							}}/>
						</g>
					</svg>
				</div>
			</Sequence>
			<Sequence from={70} durationInFrames={60} name="Hola texto">
				<HolaTexto/>
			</Sequence>
			<Sequence from={70} durationInFrames={370} name="Logo Claro">
				<LogoClaro logoColor={logoColor}/>
			</Sequence>
			<Sequence from={70} durationInFrames={370} name="Circulo puntos">
				<CirclesWhite logoColor={logoColor}/>
			</Sequence>
		</AbsoluteFill>
	);
};