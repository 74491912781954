import { 
	AbsoluteFill,
	useCurrentFrame, 
} from 'remotion';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types';

export const myCompSchema2 = z.object({
	logoColor: zColor(),
});

export const OverlayIntroduccion: React.FC<z.infer<typeof myCompSchema2>> = ({
	logoColor
}) => { 
	const frame = useCurrentFrame(); 
	const boxAnimation = 0 + (frame * 100);
	
	return (
		<AbsoluteFill style={{
			backgroundColor: '#000',
			width: 800,
			height: 800,
			left: '50%',
			top: 50,
			opacity: 0.6,
			borderRadius: '100%',
			transform: 'translateX(-50%)',
			boxShadow: `0 0 0 ${boxAnimation}px #000`
		}}/>
	);
};