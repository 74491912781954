import {
	Img,
	staticFile,
	AbsoluteFill,
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion'; 
export const ClaroFinal = () => {
	// Const videoConfig = useVideoConfig();
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig(); 

	const translateAnimation = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: -1000,
		to: -50
	});

	const scaleAnimation = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 7,
		to: 1
	});

	return (
		<AbsoluteFill className="total-a-pagar">
			<AbsoluteFill>
				<Img className="fondo-chica" src={staticFile("CLA_MOV_01/fondo-tuplanincluye.jpg")} style={{
					position: "absolute"
				}}/>
				<Img className="claro-logo" src={staticFile("CLA_MOV_01/claro-logo-completo.svg")} style={{
					position: "absolute",
					width: 502,
					top: "50%",
					left: "50%",
					transform: `translate(-50%, ${translateAnimation}%) scale(${scaleAnimation})`
				}}/> 
			</AbsoluteFill>
		</AbsoluteFill>
	);
};