import {
	Img,
	staticFile,
	AbsoluteFill,
	Sequence,
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types';

import '../fonts.css';
import { CirculoLineasNegro } from './Components/CirculoLineasNegro';
import { CirculoPuntosRojo } from './Components/CirculoPuntosRojo';
import { LogoClaro } from './Components/LogoClaro';

export const myCompSchema2 = z.object({
	logoColor: zColor(),
	totalpagar: z.string(),
});

export const TotalAPagar: React.FC<z.infer<typeof myCompSchema2>> = ({
	logoColor,
	totalpagar
}) => {
	// Const videoConfig = useVideoConfig();
	const frame = useCurrentFrame();

	const { durationInFrames, fps } = useVideoConfig();
	
	const loopDuration = 60;
	const loopFrame = frame % loopDuration;
	 
	
	const letters = "Total A Pagar";

	const translateBg = -960 - frame;

	const scaleBg = spring({
		fps,
		frame: frame * 5,
		config: { damping: 400, stiffness: 400 },
		from: 0.8,
		to: 1
	});

	const opacityUno = spring({
		fps,
		frame: loopFrame,
		from: 0,
		to: .10
	});
	const scaleUno = spring({
		fps,
		frame: loopFrame,
		config: { damping: 15, stiffness: 100 },
		from: 0,
		to: 1.4
	});

	const opacityDos = spring({
		fps,
		frame: loopFrame,
		from: 0,
		to: .35
	});
	const scaleDos = spring({
		fps,
		frame: loopFrame,
		config: { damping: 15, stiffness: 100 },
		from: 0,
		to: 1.2,
		delay: 5
	});
	const scaleTres = spring({
		fps,
		frame,
		config: { damping: 15, stiffness: 100 },
		from: 0,
		to: 1,
		delay: 10
	});

	const precioTexto = totalpagar;

	const scaleSection = spring({
		fps,
		frame,
		config: { damping: 400, stiffness: 400 },
		from: 1,
		to: 2,
		delay: (durationInFrames - 8)
	});
	const blurSection = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 10,
		delay: (durationInFrames - 8)
	});

	return (
		<AbsoluteFill className="total-a-pagar" style={{
			fontFamily: "Roboto",
			filter: (blurSection > 0)? `blur(${blurSection}px)` : '',
			transform: `scale(${scaleSection})`
		}}>
			<AbsoluteFill>
				<Img className="fondo-chica" src={staticFile("CLA_MOV_01/fondo-horizontal-blanco-min.png")} style={{
					position: "absolute",
					transform: `translate(${translateBg}px, 0px) scale(${scaleBg})`,
					width: "200%"
				}}/>
				<CirculoLineasNegro/>
				<CirculoPuntosRojo/>
				<div className="total-caja" style={{
					position: "absolute",
					top: "40%",
					left: 0,
					width: "100%",
					transform: "translateY(-50%)"
				}}>
					<h2 style={{
						fontFamily: 'AMX',
						width: "100%",
						left: 0,
						margin: 0,
						color: "#000",
						fontSize: 76,
						lineHeight: 1,
						fontWeight: 'bold',
						textAlign: "center"
					}}>
						{letters.split(' ').map((letter, index) => {
							const delayFrames = index * 5;
							const newFrames = frame * 3;
							const scaleAnimation = spring({
								frame: newFrames,
								fps,
								config: {
								damping: 200,
								},
								from: 0,
								to: 1,
								delay: delayFrames
							});
							const translateAnimation = spring({
								frame: newFrames,
								fps,
								config: {
									stiffness: 40
								},
								from: 0,
								to: 0,
								delay: delayFrames
							})
							return (
							<span
								key={index}
								style={{
									position: 'relative',
									display: "inline-block",
									transform: `scale(${scaleAnimation}) translateY(${translateAnimation}px)`,
								}}
							>
								{letter === ' ' ? '\u00A0' : letter+'\u00A0'}
							</span>
							);
						})}
					</h2>
					<div className="box-lineas">
						<div className="box-wrap">
							<div className="bx-line" style={{
								maxWidth: 570,
								opacity: opacityUno,
								transform: `translateY(-50%) scale(${scaleUno})`
							}}/>
							<div className="bx-line" style={{
								maxWidth: 624,
								opacity: opacityDos,
								transform: `translateY(-50%) scale(${scaleDos})`
							}}/>
							<div className="bx-line" style={{
								transform: `translateY(-50%) scale(${scaleTres})`
							}}/>
							<div className="precio-total" style={{
								fontSize: 120,
								fontWeight: "normal"
							}}>
								{precioTexto.split(' ').map((letter, index) => {
									const delayFrames = (index + 20) * 5;
									const newFrames = frame * 3;
									const scaleAnimation = spring({
										frame: newFrames,
										fps,
										config: {
										damping: 200,
										},
										from: 0,
										to: 1,
										delay: delayFrames
									});
									const translateAnimation = spring({
										frame: newFrames,
										fps,
										config: {
											stiffness: 40
										},
										from: 0,
										to: 0,
										delay: delayFrames
									})
									return (
									<span
										key={index}
										style={{
											position: 'relative',
											display: "inline-block",
											transform: `scale(${scaleAnimation}) translateY(${translateAnimation}px)`,
										}}
									>
										{letter === ' ' ? '\u00A0' : letter+'\u00A0'}
									</span>
									);
								})}
							</div>
							<div className="icono" style={{
								backgroundColor: "#fff",
								position: "absolute",
								display: "flex",
								right: -14,
								top: -70,
								width: 148,
								height: 148,
								borderRadius: "100%",
								alignItems: "center",
								justifyContent: "center",
								border: "5px solid #F8D7D6"
							}}>
								<Img className="icono-signo" src={staticFile("CLA_MOV_01/signo-totalpagar.svg")} style={{
									width: 18,
								}}/>
								<Img className="icono-signo" src={staticFile("CLA_MOV_01/arrow-circulo-totalpagar.svg")} style={{
									position: "absolute",
									width: 86,
									transform: `rotate(${frame}deg)`,
								}}/>
							</div>
						</div>
					</div>
				</div>
			</AbsoluteFill>
			<Sequence from={1} name="Logo Claro">
				<LogoClaro logoColor="#DA3832"/>
			</Sequence>
		</AbsoluteFill>
	);
};