import React, { ReactNode } from 'react';

import {
	Img,
	staticFile,
	AbsoluteFill,
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';

import '../../fonts.css';
interface CajaContenidosProps {
  children: ReactNode;
  boxWidth?: number;
  shadow?: boolean;
  titulo?: string;
  subtitulo?: string;
  parrafo?: string;
  precio?: string;
  textoAdicional?: string;
  textoIndicativo?: string;
  textoMiClaro?: string;
  textoValorCredito?: string;
}
export const CajaContenidos: React.FC<CajaContenidosProps> = ({
  children,
  boxWidth,
  shadow,
  titulo,
  subtitulo,
  parrafo,
  precio,
  textoAdicional,
  textoIndicativo,
  textoMiClaro,
  textoValorCredito
}) => { 
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig(); 

	const scaleNormal = spring({
		fps,
		frame,
		config: { damping: 400, stiffness: 400 },
	});

	const titleWidthAnimation = spring({
		fps,
		frame: frame * 1.5,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 100,
		delay: 10
	});

	const scaleIcono = spring({
		fps,
		frame,
		delay: 15,
	});

	const scaleMiClaro = spring({
		fps,
		frame,
		delay: 35,
	});

	const dashOffAnimation = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 1604.310302734375,
		to: 0,
		delay: 15,
	});

	const whiteDashOffAnimation = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 488.774169921875,
		to: 0,
		delay: 15,
	});
	
	const tituloScale = spring({
		frame,
		fps,
		config: {
			damping: 200,
		},
		from: 0,
		to: 1,
		delay: 10
	});

	const textoAdicionalScale = spring({
		frame,
		fps,
		config: {
			damping: 200,
		},
		from: 0,
		to: 1,
		delay: 30
	});

	const textoIndicativoScale = spring({
		frame,
		fps,
		config: {
			damping: 200,
		},
		from: 0,
		to: 1,
		delay: 40
	});

	const subtituloScale = spring({
		frame,
		fps,
		config: {
			damping: 200,
		},
		from: 0,
		to: 1,
	});

	const subtituloTranslate = spring({
		frame,
		fps,
		config: {
			damping: 200,
		},
		from: -50,
		to: 0
	});
	const precioScale = spring({
		frame,
		fps,
		config: {
			damping: 200,
		},
		from: 0,
		to: 1,
		delay: 5
	});

	const textoMiClaroScale = spring({
		frame,
		fps,
		config: {
			damping: 200,
		},
		from: 0,
		to: 1,
		delay: 35
	});

	return <>
		<AbsoluteFill className="cc-item" style={{
			display: "flex",
			gap: 60,
			flexDirection: "column",
			alignItems: "center",
			justifyContent: "center"
		}}>
			<div className="tpl-box" style={{
				backgroundColor: '#fff',
				position: "relative",
				fontFamily: "Roboto",
				width: boxWidth,
				display: "flex",
				borderRadius: 50,
				paddingTop: 70,
				paddingBottom: 50,
				minHeight: 416,
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
				transform: `scale(${scaleNormal})`,
				boxShadow: (shadow)? "0 0 18px 8px #00000024" : "0 0 0 0"
			}}>
				<h4 style={{
					position: 'absolute',
					width: "100%",
					top: -35,
					left: "50%",
					textAlign: "center",
					transform: "translateX(-50%)"
				}}>
					<div style={{
						position: "relative",
						display: "inline-block",
						fontWeight: '500',
						padding: "6px 50px",
						fontSize: 57,
						color: "#fff",
						textAlign: 'center',
					}}>
						<div className="title-bg" style={{
							backgroundColor: "#000",
							position: "absolute",
							left: 0,
							top: 0,
							width: `${titleWidthAnimation}%`,
							height: "100%",
							borderRadius: 50,
						}}/>
							<span style={{
								position: 'relative',
								display: "block",
								transform: `scale(${tituloScale})`
							}}>{titulo}</span>
					</div>
				</h4>
				{subtitulo? <h4 style={{
						fontWeight: '500',
						color: "#808080",
						fontSize: 53,
						marginBottom: 30,
						transform: `scale(${subtituloScale}) translateY(${subtituloTranslate}px)`
					}}>
						{subtitulo}
					</h4> : ''}
				{parrafo? <div className="parrafo" style={{
						fontWeight: '500',
						fontSize: 53,
						color: "#000",
						marginBottom: 15,
						transform: `scale(${subtituloScale})`
					}}>
						{parrafo}
				</div> : ""}
				<div className="caja-icono" style={{
					display: "flex",
					gap: 70,
					alignItems: "center"
				}}>
					<div className="icono" style={{
						transform: `scale(${scaleIcono})`
					}}>
						{children}
					</div>
					<h4 style={{
						fontWeight: 'normal',
						color: "#000",
						fontSize: 117,
						transform: `scale(${precioScale})`
					}}>
						{precio}
					</h4>
				</div>
				{textoValorCredito? <div className="valor-credito" style={{
					position: "relative",
					marginTop: 45,
					width: 728,
				}}>
					<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 733.01 103.67">
						<g id="Layer_1-2" data-name="Layer 1">
							<rect className="cls-1" x="2.99" y="2.99" width="727.03" height="97.69" rx="27.29" ry="27.29" style={{
								fill: "none",
								stroke: "#da3832",
								strokeMiterlimit: 10,
								strokeWidth: "5.98px",
								strokeDashoffset: `${dashOffAnimation}px`,
  								strokeDasharray: "1604.310302734375px"
							}}/>
						</g>
					</svg>
					<h4 style={{
						position: "absolute",
						left: 0,
						top: "50%",
						width: "100%",
						fontWeight: '500',
						fontSize: 53,
						textAlign: "center",
						transform: `translateY(-50%) scale(${subtituloScale})`
					}}>
						{textoValorCredito}
					</h4>
				</div> : ''}
			</div>
			<div className="box-footer" style={{
				position: "relative",
				width: "100%"
			}}>
				<div className="adicional" style={{
					display: "flex",
					gap: 20,
					alignItems: "center",
					justifyContent: "center"
				}}>
					{textoAdicional?
						<span style={{
							fontSize: 42,
							color: "#fff",
							fontWeight: 'bold',
							transform: `scale(${textoAdicionalScale})`
						}}>{textoAdicional}</span>
					: ''}
					{textoIndicativo? <div className="indicativo" style={{
						backgroundColor: "transparent",
						position: "relative",
						width: 174,
						margin: 0,
					}}>
						<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177.66 84.35">
							<g id="Layer_1-2" data-name="Layer 1">
								<rect className="cls-1" x="2" y="2" width="173.66" height="80.35" rx="12.09" ry="12.09" style={{
									fill: "none",
									stroke: "#fff",
									strokeMiterlimit: 10,
									strokeWidth: 4,
									strokeDashoffset: `${whiteDashOffAnimation}px`,
  									strokeDasharray: "488.774169921875px"
								}}/>
							</g>
						</svg>
						<h6 style={{
							position: "absolute",
							color: "#fff",
							left: 0,
							top: "50%",
							width: "100%",
							fontWeight: 'bold',
							fontSize: 42,
							margin: 0,
							textAlign: "center",
							transform: `translateY(-50%) scale(${textoIndicativoScale})`
						}}>
							{textoIndicativo}
						</h6>
					</div> : ''}
				</div>
				{textoMiClaro? <div className="miclaro" style={{
					display: "flex",
					paddingTop: 20,
					gap: 52,
					alignItems: "center",
					justifyContent: "center"
				}}>
					<div className="txto" style={{
						fontWeight: '500',
						color: "#fff",
						fontSize: 28,
						marginBottom: 16,
						transform: `scale(${textoMiClaroScale})`
					}}>
						{textoMiClaro}
					</div>
					<a href="#miclaro" target="_blank" style={{
						display: "block",
						transform: `scale(${scaleMiClaro})`
					}}>
						<Img className="fondo-chica animate-clouds" src={staticFile("CLA_MOV_01/miclaro-logo.png")} style={{
							width: 129
						}}/>
					</a>
				</div> : ''}
			</div>
		</AbsoluteFill>
	</>;
};

CajaContenidos.defaultProps = {
	boxWidth: 944,
	shadow: true,
	titulo: '',
	subtitulo: '',
	parrafo: '',
	precio: '',
	textoAdicional: '',
	textoIndicativo: '',
	textoMiClaro: '',
	textoValorCredito: ''
};