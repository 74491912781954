import {
	Img,
	staticFile,
	AbsoluteFill,
	Sequence,
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types'; 

import { LogoClaro } from './Components/LogoClaro';
import { CirculoLineasRojo } from './Components/CirculoLineasRojo';
import { LineasOlasFechaLimite } from './Components/LineasOlasFechaLimite';

export const myCompSchema2 = z.object({
	logoColor: zColor(),
	fechadepago: z.string()
});

export const FechaLimitePago: React.FC<z.infer<typeof myCompSchema2>> = ({
	logoColor,
	fechadepago
}) => {
	// Const videoConfig = useVideoConfig();
	const frame = useCurrentFrame();

	const { durationInFrames, fps } = useVideoConfig(); 

	const barWidthAnimation = spring({
		fps,
		frame: frame * 2,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 733,
		delay: 10,
	});

	const widthAnimation = spring({
		fps,
		frame: frame * 1.5,
		config: { damping: 100, stiffness: 100 },
		from: 640,
		to: 740,
	});

	const heightAnimation = spring({
		fps,
		frame: frame * 1.5,
		config: { damping: 100, stiffness: 100 },
		from: 240,
		to: 354,
	});

	const scaleLineas = spring({
		fps,
		frame: frame * 1.5,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 1,
		delay: 20
	});
	const leftAnimation = spring({
		fps,
		frame: frame * 1.5,
		config: { damping: 100, stiffness: 100 },
		from: -30,
		to: -50,
		delay: 20
	});
	const topAnimation = spring({
		fps,
		frame: frame * 1.5,
		config: { damping: 100, stiffness: 100 },
		from: -30,
		to: -50,
		delay: 20
	});

	const textoLimite = "Fecha límite de pago";
	const fechaLimite = fechadepago;

	const scaleSection = spring({
		fps,
		frame,
		config: { damping: 400, stiffness: 400 },
		from: 1,
		to: 2,
		delay: (durationInFrames - 8)
	});
	const blurSection = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 10,
		delay: (durationInFrames - 8)
	});

	const textoLimiteScale = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 1,
		delay: 6
	});
	const fechaLimiteScale = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 1,
		delay: 10
	});
	return (
		<AbsoluteFill className="fecha-pago-limite" style={{
			filter: (blurSection > 0)? `blur(${blurSection}px)` : '',
			transform: `scale(${scaleSection})`
		}}>
			<AbsoluteFill>
				<Img className="fondo-chica" src={staticFile("CLA_MOV_01/fecha-limite-pago-fondo.jpg")} style={{
					position: "absolute"
				}}/>
			</AbsoluteFill>
			<Sequence from={1} durationInFrames={318} name="Logo Claro">
				<CirculoLineasRojo/>
			</Sequence>
			<Sequence from={18} durationInFrames={300} name="Lineas olas">
				<LineasOlasFechaLimite logoColor={logoColor}/>
			</Sequence>
			<Sequence from={1} durationInFrames={318} name="Fecha límite de pago">
				<AbsoluteFill>
					<div className="limite-pago" style={{
						position: "absolute",
						width: 740,
						right: 150,
						bottom: 232,
					}}>
						<div className="limite-card" style={{
							position: "relative",
							width: widthAnimation,
							height: heightAnimation,
							borderRadius: 36,
							border: "5px solid #fff"
						}}>
							<div className="white-bar" style={{
								backgroundColor: "#fff",
								position: "absolute",
								left: -1,
								top: -1,
								width: barWidthAnimation,
								height: 124,
								borderRadius: "30px 30px 0 0"
							}}/>
							<h4 style={{
								position: "relative",
								fontFamily: "Roboto",
								fontWeight: "bold",
								width: "100%",
								fontSize: 64,
								left: 0,
								top: 18,
								color: "#DA3832",
								textAlign: "center",
								transform: `scale(${textoLimiteScale})`
							}}>
								{textoLimite}
							</h4>
							<div className="card-body" style={{
								position: "absolute",
								bottom: "20%",
								left: 0,
								width: "100%",
								textAlign: "center"
							}}>
								<div className="txto" style={{
									fontFamily: "Roboto",
									fontWeight: "500",
									fontSize: 76,
									color: "#fff",
									transform: `scale(${fechaLimiteScale})`
								}}>
									{fechaLimite}
								</div>	
							</div>
							<div className="lineas-logo" style={{
								position: "absolute",
								right: leftAnimation,
								top: topAnimation,
								transform: `scale(${scaleLineas})`
							}}>
								<Img className="no-cuenta-fondo" src={staticFile("CLA_MOV_01/lineas-logo.svg")} style={{
									width: 61
								}}/>
							</div>
						</div>
					</div>
				</AbsoluteFill>
			</Sequence>
			<Sequence name="Logo Claro" durationInFrames={318}>
				<LogoClaro logoColor="#DA3832"/>
			</Sequence>
		</AbsoluteFill>
	);
};