import { 
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';

import '../../fonts.css';

import React, { ReactNode } from 'react';

interface ElementoEstadoCuentaProps {
  children: ReactNode;
  shadow?: boolean;
  titulo?: string;
  precio?: string;
  inactive?: boolean;
  retraso?: number;
}

export const ElementoEstadoCuenta: React.FC<ElementoEstadoCuentaProps> = ({
  children,
  shadow,
  titulo,
  precio,
  inactive,
  retraso = 0
}) => {  
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig(); 

	const scaleNormal = spring({
		fps,
		frame,
		from: 0.8,
		to: 1,
		delay: retraso
	});

	const scaleIcon = spring({
		fps,
		frame,
		from: 0,
		to: 1,
		delay: retraso
	});

	const opacityNormal = spring({
		fps,
		frame,
		from: 0,
		to: 1,
		delay: retraso
	});
	const heightAnimation = spring({
		fps,
		frame,
		from: 0,
		to: 100,
		delay: retraso
	});

	const tituloScale = spring({
		frame,
		fps,
		config: {
			damping: 200,
		},
		from: 0,
		to: 1,
		delay: retraso
	});

	const precioScale = spring({
		frame,
		fps,
		config: {
			damping: 200,
		},
		from: 0,
		to: 1,
		delay: (retraso + 10)
	});
	
	return <>
		<div className="tpl-item" style={{
			position: "relative",
			fontFamily: "Roboto",
			padding: '20px 30px',
			color: "#000",
			width: "100%",
			height: "100%",
			display: "block",
			zIndex: 1,
		}}>
			<div className="tpl-bg" style={{
				backgroundColor: (inactive)? "#F2F2F2" : "#fff",
				position: "absolute",
				opacity: opacityNormal,
				left: 0,
				top: 0,
				zIndex: -1,
				width: "100%",
				borderRadius: 20,
				height: `${heightAnimation}%`,
				transform: `scale(${scaleNormal})`,
				boxShadow: (shadow)? "0 0 18px 8px #00000024" : "0 0 0 0"
			}}/>
			<div className="tpl-box" style={{
				display: 'flex',
				gap: 50,
				alignItems: 'center',
				justifyContent: "space-between"
			}}>
				<div className="col" style={{
					display: "flex",
					alignItems: "center"
				}}>
					<div className="icon" style={{
						width: 90,
						display: "flex",
						transform: `scale(${scaleIcon})`
					}}>
						{children}
					</div>
					<div className="titulo">
						<h4 style={{
							fontStyle: '500',
							fontSize: 45,
							color: (inactive)? "#CCCCCC" : "#000",
							transform: `scale(${tituloScale})`
						}}>
							{titulo}
						</h4>
					</div>
				</div>
				<div className="col">
					<div className="precio" style={{
							fontStyle: 'normal',
							fontSize: 45,
							transform: `scale(${precioScale})`
						}}>
							{precio}
					</div>
				</div>
			</div>
		</div>
	</>;
};

ElementoEstadoCuenta.defaultProps = {
	shadow: false,
	titulo: '',
	precio: '',
	inactive: false,
	retraso: 0
};