import { 
	Audio,
	staticFile,
	AbsoluteFill,
	Sequence, 
} from 'remotion';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types';

import '../fonts.css';
import { FechaLimitePago } from './FechaLimitePago';
import { QuieresPagarTuFactura } from './QuieresPagarTuFactura';
import { ClaroFinal } from './ClaroFinal';

export const myCompSchema2 = z.object({
	logoColor: zColor(),
	fechadepago: z.string()
});

export const Fija2: React.FC<z.infer<typeof myCompSchema2>> = ({
	logoColor,
	fechadepago
}) => { 

	return (
		<AbsoluteFill className="fija2" style={{
			fontFamily: "Roboto"
		}}>
			<Sequence durationInFrames={306} name="Fecha limite de pago"> 
				<FechaLimitePago logoColor="#DA3832" fechadepago={fechadepago} />
			</Sequence>
			<Sequence from={306} durationInFrames={200} name="¿Quieres pagar tu factura Claro?o"> 
				<QuieresPagarTuFactura logoColor="#DA3832"/>
			</Sequence>
			<Sequence from={510} durationInFrames={150} name="¿Quieres pagar tu factura Claro?o">
				<ClaroFinal/>
			</Sequence> 
		</AbsoluteFill>
	);
};