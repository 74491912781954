import {
	spring, 
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';

import '../../fonts.css';

export const IconoDatos = () => { 
	const frame = useCurrentFrame();

	const { fps } = useVideoConfig(); 

	const scaleAnimation = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 400 },
		from: 0,
		to: 1,
		delay: 14
	});

	return <>
		<div className="icono" style={{
			transform: `translate(25px, -15px) scale(${scaleAnimation})`,
			transformOrigin: "bottom"
		}}>
			<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118.19 111.55" style={{
				width: 118
			}}>
				<g id="Layer_1-2" data-name="Layer 1">
					<g>
						<path className="cls-1" d="m54.23,0c21.66.18,39.01,6.59,53.11,21.06,6.23,6.39,6.28,7.38-.6,12.78-1.7,1.33-3.36,2.73-5.07,4.04-1.32,1.01-2.36,1.16-3.56-.52-6.44-9.11-14.95-15.75-25.68-18.77-18.23-5.14-35.24-2.51-50.32,9.57-3.07,2.46-5.84,5.23-8.25,8.33-1.25,1.61-2.41,1.61-3.89.41-2.87-2.34-5.77-4.65-8.7-6.92-1.7-1.31-1.56-2.52-.32-4.14C8.88,15.46,19.19,8.46,31.39,4.07,39.34,1.22,47.61.37,54.23,0Zm3.53,4.15c-6.63.22-11.52.35-16.42,1.37-12.65,2.63-23.62,8.2-32.64,17.46-5.19,5.33-3.94,4.37.67,8.39,1.54,1.34,2.52,1.14,3.82-.26,9.59-10.3,21.28-16.46,35.31-18.52,9.24-1.35,18.08-.07,26.75,2.75,9.41,3.06,17.19,8.7,23.54,16.3.8.96,1.55,1.87,3.1,1.11,5.89-2.92,6.29-4.95,1.77-9.61-.29-.3-.59-.57-.89-.86-12.97-12.74-28.73-18.29-45.01-18.13Z" style={{
							strokeWidth: 0
						}}/>
						<path className="cls-1" d="m55.62,26.13c14.2.34,24.85,6.02,33.53,15.82,1.69,1.91,1.62,3.23-.45,4.72-3,2.17-5.97,4.39-8.79,6.8-2.18,1.86-3.66,1.86-5.51-.54-5.86-7.58-13.61-10.69-23.12-9.06-6,1.03-10.57,4.46-14.17,9.13-1.78,2.31-3.17,2.38-5.27.57-2.72-2.35-5.63-4.49-8.45-6.72-1.39-1.1-2.11-2.41-.67-3.91,9.27-9.64,19.93-16.52,32.9-16.8Zm29.14,17.54c-.06-.67-.49-1.09-.93-1.55-6.22-6.43-13.8-10.45-22.58-11.7-13.1-1.86-23.58,3.8-32.83,12.43-.5.47-1.37,1.11-.87,1.79,1.48,2,3.48,3.54,5.64,4.69,1.68.89,2.51-.95,3.48-1.89,10.65-10.31,25.75-10.94,36.56-1.49q4.75,4.15,9.77.04c.74-.61,1.91-.99,1.76-2.32Z" style={{
							strokeWidth: 0
						}}/>
						<g>
							<path className="cls-1" d="m64.77,73.65c0,4.01.12,8.03-.04,12.04-.11,2.63.7,3.83,3.49,3.6,2.15-.18,4.32-.05,6.48-.02.9.01,2.07-.27,2.49.74.41.99-.54,1.69-1.17,2.33-4.58,4.74-9.17,9.46-13.77,14.17-1.36,1.4-2.78,2.74-4.12,4.17-1.08,1.15-2.1,1.14-3.13.02-6.37-6.86-12.73-13.72-19.08-20.59-1.46-1.58-.82-2.35,1.11-2.38,2.88-.05,5.77-.15,8.64-.03,2.5.11,3.45-.79,3.41-3.38-.13-7.51-.06-15.03-.02-22.54.02-4,.42-4.34,4.5-4.34,2.57,0,5.15.07,7.72,0,2.53-.08,3.52,1.12,3.49,3.56-.06,4.22-.02,8.44-.02,12.66h.02Zm-4.25,2.66c0-3.81-.13-7.63.04-11.44.13-2.94-1.39-3.38-3.82-3.38-2.41,0-3.9.37-3.82,3.34.17,6.39.05,12.78.05,19.17,0,6.45-.5,7-6.88,7.57-.69.06-1.52-.16-1.89.68-.37.84.29,1.32.77,1.84,3.23,3.46,6.5,6.89,9.67,10.41,1.48,1.65,2.64,1.45,4.06-.08,2.67-2.87,5.45-5.62,8.18-8.43.56-.58,1.4-1.11,1.01-2.06-.39-.96-1.38-.7-2.17-.79-4.17-.51-5.17-1.58-5.21-5.72-.03-3.71,0-7.42,0-11.13Z" style={{
								strokeWidth: 0,
							}}/>
							<path className="cls-1" d="m88.96,88.82c0-4.23-.06-8.45.03-12.68.05-2.24-.81-3.2-3.08-3.1-2.47.11-4.95.05-7.42.03-.81,0-1.77.03-2.15-.87-.34-.82.45-1.31.92-1.8,5.96-6.12,11.95-12.21,17.92-18.32,1.26-1.29,2.4-1.31,3.66.06,6.13,6.67,12.3,13.31,18.45,19.97.48.51,1.17.98.79,1.83-.35.8-1.13.83-1.85.84-2.78.03-5.57.12-8.35,0-2.43-.1-3.28.97-3.26,3.31.07,7.73-.06,15.46.07,23.19.05,2.73-.98,3.69-3.62,3.55-2.88-.15-5.78-.15-8.66,0-2.5.13-3.56-.74-3.47-3.34.14-4.22.04-8.45.04-12.68h-.04Zm21.17-18.6c-3.28-3.57-6.4-6.98-9.53-10.38-.77-.83-1.58-1.63-2.33-2.48-1.37-1.55-2.38-.47-3.33.49-2.96,3-5.9,6.04-8.82,9.08-.49.5-1.3,1.01-1.06,1.76.33,1.03,1.39.61,2.15.65,4.67.26,5.45,1.05,5.46,5.84.02,7.52,0,15.04.02,22.56,0,1.2-.19,2.51,1.33,3.14,3.76,1.56,6.44-.16,6.45-4.2.02-6.49-.04-12.98.06-19.47.07-4.86,1.49-6.18,6.28-6.29,1-.02,2.06.22,3.32-.7Z" style={{
								strokeWidth: 0
							}}/>
						</g>
					</g>
				</g>
			</svg>		
		</div>
	</>;
};