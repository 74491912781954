import {
	AbsoluteFill,
	Sequence,
	Audio,
	staticFile,
} from 'remotion';
import './../global.css';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types';

import { useEffect } from "react";
import { preloadAudio, preloadImage } from "@remotion/preload";

import { FacturaContenidos } from './FacturaContenidos';
import { Fija2 } from './Fija2';
import { Fija1 } from './Fija1';

export const myCompSchema = z.object({ 
	logoColor: zColor(),
	tuplanincluye: z.object({
		datos: z.string(),
		hasta: z.string()
	}),
	totalpagar: z.string(),
	noCuenta: z.object({
		cuenta: z.string(),
		sandoAnterior: z.string(),
		cargoFijo: z.string(),
		otrosConceptos: z.string(),
		creditos: z.string()
	}),
	cargosFijos: z.string(),
	otrosConceptos: z.string(),
	creditos: z.object({
		equipo: z.string(),
		cuota: z.string(),
		total: z.string(),
		numeroCuota: z.string()
	}),
	creaditoMora: z.object({
		equipo: z.string(),
		mora: z.string(),
		total: z.string(),
		numeroCuota: z.string()
	}),
	consumoAdicional: z.string(),
	otrosConceptosservicios: z.string(),
	otrosConceptosserviciostxt: z.string(),
	otrosconceptoscagosadicionales: z.string(),
	otrosconceptoscagosadicionalestxt: z.string(),
	fechadepago: z.string()
});

export const Video_CLA_MOV_01: React.FC<z.infer<typeof myCompSchema>> = ({ 
	logoColor,
	tuplanincluye,
	totalpagar,
	noCuenta,
	cargosFijos,
	otrosConceptos,
	creditos,
	creaditoMora,
	consumoAdicional,
	otrosConceptosservicios,
	otrosConceptosserviciostxt,
	otrosconceptoscagosadicionales,
	otrosconceptoscagosadicionalestxt,
	fechadepago
}) => { 
	useEffect(() => {
		preloadImage(staticFile("CLA_MOV_01/factura/fondo-chica.jpg"));
		preloadImage(staticFile("CLA_MOV_01/factura/fondo-solo-chica.jpg"));
		preloadImage(staticFile("CLA_MOV_01/total-a-pagar-fondo.png"));
		preloadImage(staticFile("CLA_MOV_01/fondo-horizontal-blanco-min.png"));
		preloadImage(staticFile("CLA_MOV_01/fondo-completo-blanco.png"));
		preloadImage(staticFile("CLA_MOV_01/fondo-completo-blanco-min.png"));
		preloadImage(staticFile("CLA_MOV_01/mano-click.png"));
		preloadImage(staticFile("CLA_MOV_01/fondo-tuplanincluye.png"));
		preloadImage(staticFile("CLA_MOV_01/fondo-nocuenta.png"));
		preloadImage(staticFile("CLA_MOV_01/fondo-horizontal-blanco.png"));
		preloadImage(staticFile("CLA_MOV_01/fondo-contenidos.png"));
		preloadImage(staticFile("CLA_MOV_01/fecha-limite-pago-fondo.jpg"));
		preloadAudio(staticFile("CLA_MOV_01/audio/1_Hola.mp3"));
		preloadAudio(staticFile("CLA_MOV_01/audio/2_Hemos_Creado.mp3"));
		preloadAudio(staticFile("CLA_MOV_01/audio/3_Queremos_Revisar.mp3"));
		preloadAudio(staticFile("CLA_MOV_01/audio/4_Este_Total.mp3"));
		preloadAudio(staticFile("CLA_MOV_01/audio/5_Cargo_Fijo.mp3"));
		preloadAudio(staticFile("CLA_MOV_01/audio/6_Actualizaciones.mp3"));
		preloadAudio(staticFile("CLA_MOV_01/audio/7_Reconexion.mp3"));
		preloadAudio(staticFile("CLA_MOV_01/audio/8_Adquisicion_Tecnologia.mp3"));
		preloadAudio(staticFile("CLA_MOV_01/audio/9_Mora_Creditos.mp3"));
		preloadAudio(staticFile("CLA_MOV_01/audio/10_Larga_Distancia.mp3"));
		preloadAudio(staticFile("CLA_MOV_01/audio/11_Google.mp3"));
		preloadAudio(staticFile("CLA_MOV_01/audio/12_Videojuegos.mp3"));
		preloadAudio(staticFile("CLA_MOV_01/audio/13_Ya_conoces.mp3"));
		preloadAudio(staticFile("CLA_MOV_01/audio/14_Pagar_Factura.mp3"));
}, []);


	return (
		<AbsoluteFill style={{backgroundColor: 'white'}}> 
			<Audio src={staticFile("CLA_MOV_01/audio/audio_completo_final.mp3")} volume={0.2} />
			{/* INICIO FIJA #1 */}
			<Sequence from={1} durationInFrames={950} name="Fija1">
				<Fija1 logoColor={logoColor}  tuplanincluye={tuplanincluye} totalpagar={totalpagar} noCuenta={noCuenta} />
			</Sequence>
			{/* FINAL FIJA #1 */}
			
			{/* CONTENIDOS INICIO */}
			<Sequence from={950} durationInFrames={1210} name="Contenidos">
				<FacturaContenidos logoColor={logoColor} cargosFijos ={ cargosFijos } otrosConceptos={otrosConceptos} creditos={creditos} creaditoMora={creaditoMora} consumoAdicional={consumoAdicional} otrosConceptosservicios={otrosConceptosservicios} otrosConceptosserviciostxt={otrosConceptosserviciostxt} otrosconceptoscagosadicionales={otrosconceptoscagosadicionales}  otrosconceptoscagosadicionalestxt={otrosconceptoscagosadicionalestxt}  />
			</Sequence>
			{/* CONTENIDOS FINAL */}

			{/* INICIO FIJA #2 */}
			<Sequence from={1955} durationInFrames={630} name="Fija2">
				<Fija2 logoColor={logoColor} fechadepago={fechadepago} />
			</Sequence>
			{/* FINAL FIJA #2 */}
		</AbsoluteFill>
	);
};