import {
	Img,
	Audio,
	staticFile,
	AbsoluteFill,
	Sequence,
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types';

import '../fonts.css';
import { LogoClaro } from './Components/LogoClaro';
import { CajaContenidos } from './Components/CajaContenidos';

export const myCompSchema2 = z.object({
	logoColor: zColor(),
	cargosFijos: z.string(),
	otrosConceptos: z.string(),
	creditos: z.object({
		equipo: z.string(),
		cuota: z.string(),
		total: z.string(),
		numeroCuota: z.string()
	}),
	creaditoMora: z.object({
		equipo: z.string(),
		mora: z.string(),
		total: z.string(),
		numeroCuota: z.string()
	}),
	consumoAdicional: z.string(),
	otrosConceptosservicios: z.string(),
	otrosConceptosserviciostxt: z.string(),
	otrosconceptoscagosadicionales: z.string(),
	otrosconceptoscagosadicionalestxt: z.string(),
});

export const FacturaContenidos: React.FC<z.infer<typeof myCompSchema2>> = ({
	logoColor,
	cargosFijos,
	otrosConceptos,
	creditos,
	creaditoMora,
	consumoAdicional,
	otrosConceptosservicios,
	otrosConceptosserviciostxt,
	otrosconceptoscagosadicionales,
	otrosconceptoscagosadicionalestxt
}) => { 
	const frame = useCurrentFrame();

	const { durationInFrames, fps } = useVideoConfig(); 
	
	const scaleSection = spring({
		fps,
		frame,
		config: { damping: 400, stiffness: 400 },
		from: 1,
		to: 2,
		delay: (durationInFrames - 8)
	});
	const blurSection = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 10,
		delay: (durationInFrames - 8)
	});

	return (
		<AbsoluteFill className="total-a-pagar" style={{
			fontFamily: "Roboto",
			filter: (blurSection > 0)? `blur(${blurSection}px)` : '',
			transform: `scale(${scaleSection})`
		}}>
			<AbsoluteFill style={{
				backgroundColor: "#B21F1D"
			}}>
				<Img className="fondo-chica" src={staticFile("CLA_MOV_01/fondo-contenidos.png")} style={{
					position: "absolute"
				}}/>
			</AbsoluteFill>
			<Sequence from={1} name="Logo Claro">
				<LogoClaro logoColor="#fff"/>
			</Sequence>
			<Sequence from={1} durationInFrames={138} name="Cargos Fijos"> 
				<CajaContenidos
					boxWidth={944}
					titulo="Cargos Fijos"
					precio={cargosFijos}
					textoAdicional="Por 60 días adicionales a tu periodo facturado"
				>
					<Img className="fondo-chica" src={staticFile("CLA_MOV_01/cc-icono-cargos.svg")} style={{
						width: 123
					}}/>
				</CajaContenidos>
			</Sequence>
			<Sequence from={138} durationInFrames={139} name="Otros Conceptos"> 
				<CajaContenidos
					boxWidth={944}
					titulo="Otros Conceptos"
					precio={otrosConceptos}
					textoAdicional="Reconexión de tu servicio por mora"
				>
					<Img className="fondo-chica" src={staticFile("CLA_MOV_01/cc-icono-otros.svg")} style={{
						width: 123
					}}/>
				</CajaContenidos>
			</Sequence>
			<Sequence from={276} durationInFrames={138} name="Créditos 2"> 
				<CajaContenidos
					boxWidth={1062}
					titulo="Créditos"
					subtitulo={creditos.equipo}
					parrafo="Cuota de Equipo"
					precio={creditos.cuota}
					textoAdicional={creditos.numeroCuota}
					textoValorCredito={"Valor crédito "+creditos.total}
				>
					<Img className="fondo-chica" src={staticFile("CLA_MOV_01/cc-icono-creditos-2.svg")} style={{
						width: 123
					}}/>
				</CajaContenidos>
			</Sequence>
			<Sequence from={410} durationInFrames={107} name="Créditos 1"> 
				<CajaContenidos
					boxWidth={1062}
					titulo="Créditos"
					subtitulo={creaditoMora.equipo}
					parrafo="Mora en Tecnología"
					precio={creaditoMora.mora}
					textoAdicional={creaditoMora.numeroCuota}
					textoValorCredito={"Valor crédito "+creaditoMora.total}
				>
					<Img className="fondo-chica" src={staticFile("CLA_MOV_01/cc-icono-creditos.svg")} style={{
						width: 123
					}}/>
				</CajaContenidos>
			</Sequence>
			<Sequence from={516} durationInFrames={232} name="Consumo Digital"> 
				<CajaContenidos
					boxWidth={944}
					titulo="Consumo Adicional"
					parrafo="Cobro por LDI"
					precio={consumoAdicional}
					textoAdicional="Adquiere un paquete LDI con nosotros y recuerda usar el indicativo"
					textoIndicativo="00444"
					textoMiClaro="*En caso de llamar a otro destino, puedes adquirir paquetes de LDI con nosotros usando tu App"
				>
					<Img className="fondo-chica" src={staticFile("CLA_MOV_01/cc-icono-consumo.svg")} style={{
						width: 123
					}}/>
				</CajaContenidos>
			</Sequence>
			<Sequence from={748} durationInFrames={100} name="Otros Conceptos 2"> 
				<CajaContenidos
					boxWidth={944}
					titulo="Otros Conceptos"
					precio={otrosConceptosservicios}
					textoAdicional={otrosConceptosserviciostxt}
				>
					<Img className="fondo-chica" src={staticFile("CLA_MOV_01/cc-icono-otros-2.svg")} style={{
						width: 123
					}}/>
				</CajaContenidos>
			</Sequence>
			<Sequence from={847} durationInFrames={165} name="Otros Conceptos 3"> 
				<CajaContenidos
					boxWidth={944}
					titulo="Otros Conceptos"
					precio={otrosconceptoscagosadicionales}
					textoAdicional={otrosconceptoscagosadicionalestxt}
				>
					<Img className="fondo-chica" src={staticFile("CLA_MOV_01/cc-icono-otros-3.svg")} style={{
						width: 123
					}}/>
				</CajaContenidos>
			</Sequence>
		</AbsoluteFill>
	);
};