import {
	Img,
	staticFile,
	AbsoluteFill,
	Sequence,
	spring,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';
import {z} from 'zod';
import {zColor} from '@remotion/zod-types';

import '../fonts.css';
import { LogoClaro } from './Components/LogoClaro';
import { LitaEstadoCuenta } from './Components/ListaEstadoCuenta';

export const myCompSchema2 = z.object({
	logoColor: zColor(),
	totalpagar: z.string(),
	noCuenta: z.object({
		cuenta: z.string(),
		sandoAnterior: z.string(),
		cargoFijo: z.string(),
		otrosConceptos: z.string(),
		creditos: z.string()
	}),
});

export const NoCuenta: React.FC<z.infer<typeof myCompSchema2>> = ({
	logoColor,
	totalpagar,
	noCuenta
}) => {
	// Const videoConfig = useVideoConfig();
	const frame = useCurrentFrame();

	const { durationInFrames, fps } = useVideoConfig();
	
	const loopDuration = 60;
	const loopFrame = frame % loopDuration;
	 

	const opacityUno = spring({
		fps,
		frame: loopFrame,
		from: 0,
		to: .10
	});
	const scaleUno = spring({
		fps,
		frame: loopFrame,
		config: { damping: 15, stiffness: 100 },
		from: 0,
		to: 1.4
	});

	const opacityDos = spring({
		fps,
		frame: loopFrame,
		from: 0,
		to: .35
	});
	const scaleDos = spring({
		fps,
		frame: loopFrame,
		config: { damping: 15, stiffness: 100 },
		from: 0,
		to: 1.2,
		delay: 5
	});
	const scaleTres = spring({
		fps,
		frame,
		config: { damping: 15, stiffness: 100 },
		from: 0,
		to: 1,
		delay: 10
	});

	const scaleCuenta = spring({
		fps,
		frame,
		from: 0,
		to: 1,
	});

	const translateCuenta = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: -50,
		to: -120,
		delay: 30
	});
	const scaleCuentaLineas = spring({
		fps,
		frame,
		from: 0,
		to: 1,
		delay: 40
	});
	
	const noCuentaTexto = "No. de cuenta";
	const noCuentaNumero = noCuenta.cuenta;
	const totalPagarTexto = "Total a Pagar";
	const totalPagarNumero = totalpagar;

	const scaleSection = spring({
		fps,
		frame,
		config: { damping: 400, stiffness: 400 },
		from: 1,
		to: 2,
		delay: (durationInFrames - 8)
	});
	const blurSection = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 10,
		delay: (durationInFrames - 8)
	});

	const scaleNoCuentaTexto = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 1,
		delay: 15
	});

	const totalPagarTextoScale = spring({
		fps,
		frame,
		config: { damping: 100, stiffness: 100 },
		from: 0,
		to: 1,
		delay: 30
	});

	return (
		<AbsoluteFill className="no-cuenta" style={{
			fontFamily: "Roboto",
			filter: (blurSection > 0)? `blur(${blurSection}px)` : '',
			transform: `scale(${scaleSection})`
		}}>
			<AbsoluteFill>
				<Img className="no-cuenta-fondo" src={staticFile("CLA_MOV_01/fondo-nocuenta.jpg")} style={{
					position: "absolute"
				}}/>
				<div className="total-caja" style={{
					position: "absolute",
					top: "50%",
					left: "6%",
					width: 638,
					transform: "translateY(-50%)"
				}}>
					<div className="box-lineas v-dos" style={{
						padding: 0
					}}>
						<div className="box-wrap" style={{
							flexDirection: "column"
						}}>
							<div className="bx-line" style={{
								maxWidth: 428,
								minHeight: 400,
								opacity: opacityUno,
								transform: `translateY(-50%) scale(${scaleUno})`
							}}/>
							<div className="bx-line" style={{
								maxWidth: 448,
								minHeight: 420,
								opacity: opacityDos,
								transform: `translateY(-50%) scale(${scaleDos})`
							}}/>
							<div className="bx-line" style={{
								transform: `translateY(-50%) scale(${scaleTres})`
							}}/>
							<div className="nocuenta" style={{
								backgroundColor: "#DA3832",
								position: "absolute",
								left: 0,
								top: "50%",
								borderRadius: 24,
								width: "100%",
								color: "#fff",
								marginBottom: 45,
								padding: "10px 0",
								textAlign: "center",
								transform: `translateY(${translateCuenta}%) scale(${scaleCuenta})`
							}}>
								<Img className="no-cuenta-fondo" src={staticFile("CLA_MOV_01/lineas-logo.svg")} style={{
									position: "absolute",
									right: -40,
									top: -40,
									width: 61,
									transform: `scale(${scaleCuentaLineas})`
								}}/>
								<div className="precio-total" style={{
									fontSize: 59,
									fontWeight: "500",
									transform: `scale(${scaleNoCuentaTexto})`
								}}>
									{noCuentaTexto}
								</div>
								<div className="precio-total" style={{
									fontSize: 59,
									fontWeight: "500",
									transform: `scale(${scaleNoCuentaTexto})`
								}}>
									{noCuentaNumero}
								</div>
							</div>
							<div className="totalpagar" style={{
								textAlign: "center",
								paddingTop: 145
							}}>
								<div className="precio-total" style={{
									fontSize: 52,
									fontWeight: "bold",
									transform: `scale(${totalPagarTextoScale})`
								}}>
									{totalPagarTexto}
								</div>
								<div className="precio-total" style={{
									fontSize: 81,
									fontWeight: "normal",
									transform: `scale(${totalPagarTextoScale})`
								}}>
									{totalPagarNumero}
								</div>
							</div>
						</div>
					</div>
				</div>
				<Sequence from={20} name="Estado Cuenta">
					<LitaEstadoCuenta sandoAnterior={noCuenta.sandoAnterior} cargoFijo={noCuenta.cargoFijo} otrosConceptos={noCuenta.otrosConceptos} creditos={noCuenta.creditos} />
				</Sequence>
			</AbsoluteFill>
			<Sequence from={1} name="Logo Rojo">
				<LogoClaro logoColor="#DA3832"/>
			</Sequence>
		</AbsoluteFill>
	);
};